import React from "react";
import "./about.css";
import ME from "../../assets/pp.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Who Am I</h5>
      <h2 class="about-me-text">About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <p>
            As a third-year student studying Management Engineering at the
            University of Waterloo, I am driven by{" "}
            <strong class="bold">
              creating great problem solving products that have a direct impact
              on the users quality of life.
            </strong>{" "}
            With a multidisciplinary background in{" "}
            <strong class="bold">
              computer engineering, data analysis, and design,
            </strong>{" "}
            I am able to apply transferable principles across multiple fields.
          </p>

          <p>
            Away from the desk I love to play basketball, hike, collect board
            games, travel, and try all kinds of new foods!
          </p>

          <div className="about__cards">
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Co-ops</h5>
              <small>5 Internships</small>
            </article>
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Awards</h5>
              <small>3+ Scholarships</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>10+ Completed</small>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
