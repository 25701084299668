import React from "react";
import "./services.css";
import { BiCard, BiCheck } from "react-icons/bi";

const Services = () => {
  return (
    <section id="services">
      <h5>What I can do</h5>
      <h2>Skills & Tools</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Product Management</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Experimentation and data analysis</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Google Analytics</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>JIRA</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Roadmapping</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Sprint Planning</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Strategy development</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Usability Testing</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>User and Market Research</p>
            </li>
          </ul>
        </article>
        {/* END OF PM */}
        <article className="service">
          <div className="service__head">
            <h3>Software Development</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>C++</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Java</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Node.js</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>PyTorch</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Python</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>R</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>React.js</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>SQL</p>
            </li>
          </ul>
        </article>
        {/* WEB DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>A/B Testing</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Adobe XD</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Design Fidelity</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Figma</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Information Architecture</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>User Feedback/Interviews</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>User Flows</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Wireframing</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
