import * as React from "react";
import "./experience.css";
import { BsPatchCheckFill } from "react-icons/bs";

// Materials UI
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CategoryIcon from "@mui/icons-material/Category";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import LayersIcon from "@mui/icons-material/Layers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  timelineDot: {
    color: "var(--color-primary)",
  },
});

const Experience = () => {
  const classes = useStyles();
  return (
    <section id="experience">
      <h5>My Lastest Co-op</h5>
      <h2>Experience</h2>

      <div className="container">
        <Timeline position="alternate">
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
            >
              January 2020 - April 2020
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot style={{ background: "#0A8300" }}>
                <DeviceHubIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography class="exp-position" variant="h6" component="span">
                Back-end Developer Intern
              </Typography>
              <Typography class="exp-company">@Hatch</Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
            >
              September 2020 - December 2020
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot style={{ background: "#0A8300" }}>
                <ViewQuiltIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography class="exp-position" variant="h6" component="span">
                Front-end Developer Intern
              </Typography>
              <Typography class="exp-company">@Revive</Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
            >
              May 2021 - August 2021
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot style={{ background: "#0A8300" }}>
                <LayersIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography class="exp-position" variant="h6" component="span">
                Full-Stack Developer Intern
              </Typography>
              <Typography class="exp-company">@E.S.</Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: "auto 0" }} variant="body2">
              January 2022 - April 2022
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot style={{ background: "#0A8300" }}>
                <CategoryIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography class="exp-position" variant="h6" component="span">
                Product Manager Intern
              </Typography>
              <Typography class="exp-company">@GoFleet</Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: "auto 0" }} variant="body2">
              September 2022 - December 2022
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot style={{ background: "#0A8300" }}>
                <CategoryIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography class="exp-position" variant="h6" component="span">
                Product Manager Intern
              </Typography>
              <Typography class="exp-company">@The Weather Network</Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={{ m: "auto 0" }} variant="body2">
              May 2022 - August 2022
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
              <TimelineDot color="secondary">
                <RepeatIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography class="exp-position" variant="h6" component="span">
                Looking for co-op!
              </Typography>
              <Typography class="exp-company">Let's Chat</Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
    </section>
  );
};

export default Experience;
