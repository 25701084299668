import React, { useState, useEffect } from "react";
import "./nav.css";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { BiMessageSquareDetail } from "react-icons/bi";

const Nav = () => {
  const [size, setSize] = useState(document.documentElement.clientWidth);
  useEffect(() => {
    setSize(document.documentElement.clientWidth);
  });
  const [activeNav, setActiveNav] = useState("#");
  if (size > 600) {
    return (
      <nav id="nav">
        <a
          class="desktop"
          href="#"
          onClick={() => setActiveNav("#")}
          className={activeNav === "#" ? "active" : ""}
        >
          Home
        </a>
        <a
          href="#about"
          onClick={() => setActiveNav("#about")}
          className={activeNav === "#about" ? "active" : ""}
        >
          About
        </a>
        <a
          href="#services"
          onClick={() => setActiveNav("#services")}
          className={activeNav === "#services" ? "active" : ""}
        >
          Skills
        </a>
        <a
          href="#experience"
          onClick={() => setActiveNav("#experience")}
          className={activeNav === "#experience" ? "active" : ""}
        >
          Experiences
        </a>
        <a
          href="#portfolio"
          onClick={() => setActiveNav("#contact")}
          className={activeNav === "#portfolio" ? "active" : ""}
        >
          Projects
        </a>
      </nav>
    );
  }
  return (
    <nav id="nav">
      <a
        href="#"
        onClick={() => setActiveNav("#")}
        className={activeNav === "#" ? "active" : ""}
      >
        <AiOutlineHome class="mobile" />
      </a>
      <a
        href="#about"
        onClick={() => setActiveNav("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#experience"
        onClick={() => setActiveNav("#experience")}
        className={activeNav === "#experience" ? "active" : ""}
      >
        <BiBook />
      </a>
      <a
        href="#services"
        onClick={() => setActiveNav("#services")}
        className={activeNav === "#services" ? "active" : ""}
      >
        <RiServiceLine />
      </a>
      <a
        href="#contact"
        onClick={() => setActiveNav("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
  );
};

var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  if (document.documentElement.clientWidth > 600) {
    var currentScrollPos = window.pageYOffset;
    console.log(document.documentElement.clientWidth);
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("nav").style.top = "2rem";
    } else {
      document.getElementById("nav").style.top = "-4.5rem";
    }
    prevScrollpos = currentScrollPos;
  }
};
export default Nav;
