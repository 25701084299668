import React from "react";
import "./header.css";
import CTA from "./CTA";
import HeaderSocial from "./HeaderSocials";

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <div className="spacer"></div>
        <div className="greeting">
          <h2 class="animated-text-greeting">
            Hello, I'm Sunischit (Sun) Thapa
          </h2>
        </div>
        <div class="fancy">
          {" "}
          <h1 class="word-effect animated-text-header">
            Technical Product Manager
          </h1>
        </div>

        <div className="description">
          <p class="animated-text-description">
            Over my past co-op terms, I have worked with established companies
            and growing startups to successfully{" "}
            <strong>plan, build, test, improve, and launch</strong> various
            digital products and services
          </p>{" "}
        </div>
        <div className="CTA">
          <CTA />
          <HeaderSocial />
        </div>

        {/* <div className="me">
          <img src={ME} alt="me" />
        </div> */}
      </div>
    </header>
  );
};

export default Header;
